import Papa from "papaparse";

const CsvToJson = async (path) => {
  let example_csv = await fetch(path);
  let csvText = await example_csv.text();

  return new Promise((resolve, reject) => {
    Papa.parse(csvText, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        // Map and clean up the data
        const data = result.data.map((row) => ({
          ...row,
          Achievements: row.Achievements
            ? row.Achievements.split("\n").map((item) =>
                item.replace(/^- /, "").trim()
              )
            : [],
          Applications: row.Applications
            ? row.Applications.split("\n").map((item) => {
                const [name, link] = item.split("==").map((s) => s.trim());
                return [name, link];
              })
            : [],
        }));
        resolve(data);
      },
      error: (error) => {
        console.error("Error parsing CSV: ", error);
        reject(error);
      },
    });
  });
};

export default CsvToJson;
