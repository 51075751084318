import React from "react";
import AchievementCard from "./achievementcard";

const Achievements = ({ data }) => {
  const isVisible = true;

  return (
    <div
      className={`flex flex-col w-1/3 bg-[#A79AB2] ml-2 color-[#202020] flex-grow rounded-[44px] max-h-[calc(100vh-180px)] transition-transform duration-400 transform ${
        isVisible ? "translate-x-0" : "translate-x-full"
      }`}
      style={{
        animation: isVisible
          ? "slideIn 0.4s ease-in-out forwards"
          : "slideOut 0.4s ease-in-out forwards",
      }}
    >
      <h1 className="text-4xl p-[32px]">Achievements</h1>
      <hr className="border-[#202020] border-1 opacity-20" />
      <div className="flex flex-col w-full items-center p-[15px] pd:p-[0px] px-[15px] pt-[15px] overflow-y-scroll no-scrollbar ">
        {data.map((item, index) => {
          return <AchievementCard item={item} data={data} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Achievements;
