import React, { useEffect, useState } from "react";
import Category from "./category.js";

const Map = ({ data, selected, setSelected, hideTitle = false }) => {
  const [groupedData, setGroupedData] = useState([]);

  function groupByCategory(data) {
    return data.reduce((acc, item) => {
      if (!acc[item.Category]) {
        acc[item.Category] = [];
      }
      acc[item.Category].push(item);
      return acc;
    }, {});
  }
  function reorderObject(obj, keyOrder) {
    let reorderedObj = {};

    // Loop through the keyOrder array and reorder the properties of the object
    keyOrder.forEach((key) => {
      if (key in obj) {
        reorderedObj[key] = obj[key];
      }
    });

    return reorderedObj;
  }

  let indexOrder = [
    "AI Model Development",
    "Generative AI",
    "Agentic AI",
    "Predictive Systems",
    "AI Data Pipeline",
    "Compute",
    "AI-Powered Tools",
    "Specialized Problem Solving",
    "General Infrastructure",
  ];

  useEffect(() => {
    if (data) {
      let tempData = groupByCategory(data);
      if (!hideTitle) {
        tempData = reorderObject(tempData, indexOrder);
      }
      setGroupedData(tempData);
    }
  }, [data]);

  return (
    <div className="bg-[#5603AD] rounded-[24px] md:rounded-[44px] w-full flex flex-col container color-[#202020] w-2/3 max-w-6xl max-h-[calc(100vh-180px)]">
      {hideTitle ? null : (
        <h1 className="text-4xl p-[32px] text-[#F0FFF1]">Bittensor Subnets</h1>
      )}
      {hideTitle ? null : (
        <hr className="border-[#F0FFF1] border-1 opacity-20" />
      )}
      {/* Container for categories */}
      <div className="columns-1 md:columns-2 gap-1 space-y-1 p-[12px] md:p-[32px] overflow-y-scroll overflow-x-hidden">
        {Object.keys(groupedData).map((category, categoryIndex) => (
          <Category
            key={categoryIndex}
            category={category}
            subnets={groupedData[category]}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
        <br />
      </div>
    </div>
  );
};

export default Map;
