import Clock from "../assets/images/clock.png";

const AchievementCard = ({ item, data, hideHeader = false }) => {
  return (
    <div
      className="w-full flex flex-col relative rounded-[20px] pb-4 cursor-pointer"
      onClick={() => window.open(item["Link"], "_blank")}
    >
      {hideHeader ? null : (
        <div className="bg-[#202020] text-[#A79AB2] rounded-[20px] py-4 px-5">
          <div className="flex justify-between items-center">
            <span className="text-xl ">{item["Subnet Name"]}</span>
            <div className="flex">
              <div className="bg-[#202020] flex justify-center items-center w-[36px] h-[36px] rounded-xl text-[#A79AB2] p-2 text-lg">
                SN
              </div>
              <div className="text-[#202020] flex justify-center items-center w-[36px] h-[36px] rounded-xl bg-[#A79AB2] p-2 text-lg">
                {item["Subnet Number"]}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex bg-[#202020] text-[#A79AB2] text-md rounded-[20px] py-5 px-5 items-center relative overflow-hidden">
        <span className="text-lg ">{item["Description"]}</span>
        <img
          src={Clock}
          alt="Achievement icon"
          className="absolute bottom-[-12px] right-[-8px] w-[50px] h-[50px] rotate-6"
        />
      </div>
      {data && data.indexOf(item) < data.length - 1 ? (
        <hr className="border-[#202020] border-1 opacity-20 mt-[16px]" />
      ) : null}
    </div>
  );
};

export default AchievementCard;
