import React from "react";
import Subnet from "./subnet.js";

const Category = ({ category, subnets, selected, setSelected }) => {
  return (
    <div className="bg-[#202020] rounded-[20px] md:rounded-[32px] flex flex-col text-white mb-0 p-[12px] md:p-[20px] break-inside-avoid">
      <h1 className="text-xl md:text-2xl pb-[10px]">{category}</h1>
      {/* Loop through the subnets */}
      {subnets.map((subnet, subnetIndex) => (
        <Subnet
          key={subnetIndex}
          subnet={subnet}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
};

export default Category;
