import React from "react";

const UtilityButton = ({ name, icon: Icon, url, app = false }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex group items-center justify-between w-full p-5 my-1 text-[#202020] text-xl bg-[#B4B8AB] rounded-2xl hover:bg-[#7D01DE] hover:text-[#F0FFF1]"
    >
      <span>{name}</span>
      <div className="flex items-center transform transition-transform ease-out duration-200 group-hover:-translate-x-2">
        {app ? (
          <div className="text-lg font-Aeonik font-normal bg-black text-white rounded-[12px] px-2 py-1 mr-2">
            APP
          </div>
        ) : null}
        <Icon className="w-5 h-5" />
      </div>
    </a>
  );
};

export default UtilityButton;
