import React from "react";
import logo from "../assets/images/new_logo.png";

const Header = () => {
  return (
    <div className="flex w-full h-fit flex-none bg-[#202020] py-[16px] md:py-[24px] justify-center items-center rounded-[40px]">
      <div className="flex items-center">
        <img src={logo} alt="logo" className=" h-8 md:h-10 " />
      </div>
    </div>
  );
};

export default Header;
