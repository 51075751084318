import Map from "../map";

const SubnetsTab = ({ data, selected, setSelected }) => {
  return (
    <div className="flex w-full h-fit bg-[#5603AD] rounded-b-3xl overflow-hidden">
      <Map
        data={data}
        selected={selected}
        setSelected={setSelected}
        hideTitle={true}
      />
      <br />
    </div>
  );
};

export default SubnetsTab;
