import React, { useState } from "react";
import AchievementsTab from "./achievementstab";
import SubnetsTab from "./subnetstab";
import SlidingCard from "./slidingpopout";

const BaseMobile = ({ data, achievements, selected, setSelected }) => {
  const [activeTab, setActiveTab] = useState("Bittensor Subnets");
  const tabs = ["Bittensor Subnets", "Achievements"];

  return (
    <div className="flex flex-col w-full flex-grow text-white">
      <div className="flex w-full">
        {tabs.map((tab) => {
          if (tab === "Bittensor Subnets") {
            return (
              <div className="flex flex-col w-full">
                <button
                  key={tab}
                  className={`flex-1 w-full text-lg rounded-tl-3xl rounded-tr-xl px-[18px] pt-[14px] pb-[18px] ${
                    activeTab === tab
                      ? " bg-[#5603AD] text-white"
                      : "bg-[#202020] text-white"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
                {activeTab === tab ? (
                  <hr className="border-[#F0FFF1] border-1 opacity-40" />
                ) : null}
              </div>
            );
          } else if (tab === "Achievements") {
            return (
              <div className="flex flex-col w-full">
                <button
                  key={tab}
                  className={`flex-1 w-full text-lg rounded-tr-3xl rounded-tl-xl px-[18px] pt-[14px] pb-[18px] ${
                    activeTab === tab
                      ? " bg-[#A79AB2] text-black"
                      : "bg-[#202020] text-white"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
                {activeTab === tab ? (
                  <hr className="border-[#202020] border-1 opacity-20" />
                ) : null}
              </div>
            );
          }
          return null;
        })}
      </div>
      {activeTab === "Bittensor Subnets" ? (
        <SubnetsTab data={data} selected={selected} setSelected={setSelected} />
      ) : (
        <AchievementsTab data={achievements} />
      )}
      {selected ? (
        <SlidingCard
          subnet_data={data.find((item) => item["Subnet Name"] === selected)}
          achievement_data={achievements.filter(
            (the_item) =>
              typeof the_item["Subnet Name"] === "string" &&
              the_item["Subnet Name"].toLowerCase() ===
                data
                  .find((item) => item["Subnet Name"] === selected)
                  ["Subnet Title"].toLowerCase()
          )}
          setSelected={setSelected}
        />
      ) : null}
    </div>
  );
};

export default BaseMobile;
