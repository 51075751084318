import React from "react";

const Subnet = ({ data, subnet, selected, setSelected }) => {
  return (
    <div
      className={
        "flex bg-[#A79AB2] rounded-[20px] text-black justify-between items-center cursor-pointer text-white my-[1px] p-[12px] group " +
        (selected === subnet["Subnet Name"]
          ? "bg-[#F0FFF1] hover:bg-[#F0FFF1]"
          : "hover:bg-[#5603AD]")
      }
      onClick={() => {
        if (selected === subnet["Subnet Name"]) {
          setSelected(null);
          return;
        }
        setSelected(subnet["Subnet Name"]);
      }}
    >
      <div className="flex">
        <div className="bg-[#202020] flex justify-center items-center w-[36px] h-[36px] rounded-xl text-[#F0FFF1] p-2 text-lg">
          SN
        </div>
        <div className="text-[#F0FFF1] flex justify-center items-center w-[36px] h-[36px] rounded-xl bg-[#5603AD] p-2 text-lg">
          {subnet["Subnet Number"]}
        </div>
      </div>
      <div
        className={
          "flex text-[#202020] items-center text-base md:text-xl transform transition-transform ease-out duration-200 group-hover:-translate-x-2 " +
          (selected !== subnet["Subnet Name"] ? " group-hover:text-white" : "")
        }
      >
        {subnet["Subnet Title"]}
      </div>
    </div>
  );
};

export default Subnet;
