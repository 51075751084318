import AchievementCard from "../achievementcard";

const AchievementsTab = ({ data }) => {
  return (
    <div className="flex w-full h-full bg-[#A79AB2] rounded-b-3xl overflow-y-scroll no-scrollbar">
      <div className="flex flex-col w-full h-full p-[32px] items-center justify-top">
        {data.map((item, index) => {
          return <AchievementCard item={item} key={index} />;
        })}
      </div>
      <br />
    </div>
  );
};

export default AchievementsTab;
