import React, { useState } from "react";
import { FiArrowUpRight, FiGithub, FiTwitter, FiGlobe } from "react-icons/fi";
import { TiDocumentText } from "react-icons/ti";
import UtilityButton from "../utilitybutton";
import close from "../../assets/images/close.svg";
import AchievementCard from "../achievementcard";

const SlidingCard = ({ subnet_data, achievement_data, setSelected }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setSelected(null);
    }, 400);
  };

  return (
    <div className="absolute h-screen z-20">
      <div
        className={`opacity-100 fixed bottom-0 left-0 w-full h-[70vh] bg-[#202020] shadow-lg rounded-t-3xl p-4  transition-transform duration-400 overflow-y-scroll no-scrollbar transform ${
          isVisible ? "translate-y-0" : "translate-y-full"
        }`}
        style={{
          animation: isVisible
            ? "slideUp 0.4s forwards"
            : "slideDown 0.4s forwards",
        }}
      >
        <div className="flex justify-between items-center p-[32px]">
          <div className="flex flex-col">
            <h1 className="text-3xl text-[#F0FFF1]">
              {subnet_data["Subnet Title"]}
            </h1>
            <h3 className="text-lg text-[#919190]">
              {subnet_data["Subnet Name"]}
            </h3>
          </div>
          <button
            className="text-[#202020] text-xl w-[28px] h-[28px] rounded-full flex justify-center items-center"
            onClick={handleClose}
          >
            <img className="flex w-full h-full" src={close} alt="close" />
          </button>
        </div>
        <hr className="border-[#DCE0D9] border-1 opacity-30" />
        <div className="flex mt-8 px-8">
          <div className="bg-[#202020] flex justify-center items-center w-[36px] h-[36px] rounded-xl text-[#F0FFF1] p-2 text-lg">
            SN
          </div>
          <div className="text-[#F0FFF1] flex justify-center items-center w-[36px] h-[36px] rounded-xl bg-[#5603AD] p-2 text-lg">
            {subnet_data["Subnet Number"]}
          </div>
        </div>
        <span className="flex mt-3 px-8 text-lg text-[#A69F98]">
          {subnet_data["Description"]}
        </span>
        <div className="flex flex-col mt-8 px-8">
          {subnet_data["Website Link"] ? (
            <UtilityButton
              name="Website"
              icon={FiGlobe}
              url={subnet_data["Website Link"]}
            />
          ) : null}
          {subnet_data["Github Link"] ? (
            <UtilityButton
              name="Github"
              icon={FiGithub}
              url={subnet_data["Github Link"]}
            />
          ) : null}
          {subnet_data["Twitter Link"] ? (
            <UtilityButton
              name="Twitter"
              icon={FiTwitter}
              url={subnet_data["Twitter Link"]}
            />
          ) : null}
          {subnet_data["Whitepaper Link"] ? (
            <UtilityButton
              name="Whitepaper"
              icon={TiDocumentText}
              url={subnet_data["Whitepaper Link"]}
            />
          ) : null}
          {subnet_data["Applications"].length > 0
            ? subnet_data["Applications"].map((item, index) => (
                <UtilityButton
                  key={index}
                  name={item[0]}
                  icon={FiArrowUpRight}
                  url={item[1]}
                  app={true}
                />
              ))
            : null}
          <div className="mb-8"></div>
          {achievement_data.length > 0 ? (
            <h1 className="text-2xl text-[#F0FFF1] my-2">Achievements</h1>
          ) : null}
          {achievement_data.length > 0
            ? achievement_data.map((item, index) => (
                <AchievementCard item={item} key={index} hideHeader={true} />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default SlidingCard;
